import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  guests: [
    {
      id: 1,
      name: "",
      number: "",
      count : 1
    },
    {
      id: 2,
      name: "",
      number: "",
      count : 1
    },
    {
      id: 3,
      name: "",
      number: "",
      count : 1
    },
    {
      id: 4,
      name: "",
      number: "",
      count : 1
    },
  ],
  save : false
};

const GuestSlice = createSlice({
  name: "guests",
  initialState,
  reducers: {
    
    Add_Guest: (state) => {
      const maxId = Math.max(...state.guests.map(guest => guest.id), 0)
      state.guests.push({
        id: maxId + 1,
        name: "",
        number: "",
        count : 1
      });
      state.save = false
    },
    Update_Guest_Name: (state, action) => {
      const requestedObject = state.guests.filter(
        (obj) => obj.id === action.payload.id 
      )[0];
      requestedObject.name = action.payload.name;
      state.save = false
    },
    Update_Guest_Number: (state, action) => {
      const requestedObject = state.guests.filter(
        (obj) => obj.id === action.payload.id
      )[0];
      requestedObject.number = action.payload.number;
      state.save = false
    },
    Update_Guest_Count : (state , action) => {
      const requestedObject = state.guests.filter(
        (obj) => obj.id === action.payload.id
      )[0];
      requestedObject.count = action.payload.count;
      state.save = false
    },
    Save_Guest : (state) => {
      state.save = true
    },
    Delete_Guest: (state, action) => {
      state.guests = state.guests.filter((obj) => obj.id !== +action.payload.id);
      state.save = false;
    },
  }
  
});

export default GuestSlice;

export const { Add_Guest, Update_Guest_Name, Update_Guest_Number , Update_Guest_Count,Save_Guest , Delete_Guest } =
  GuestSlice.actions;
