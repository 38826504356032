import { Box, Typography } from '@mui/material'
import React from 'react'

const ProfileSection = () => {
  return (
    <Box
        sx={{
            display : 'flex',
            alignItems : 'center',
            gap : 1
        }}
    >
        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.5 3.09375C9.10787 3.09375 3.09375 9.10787 3.09375 16.5C3.09375 23.8921 9.10787 29.9062 16.5 29.9062C23.8921 29.9062 29.9062 23.8921 29.9062 16.5C29.9062 9.10787 23.8921 3.09375 16.5 3.09375ZM13.2632 10.6232C14.0798 9.75756 15.229 9.28125 16.5 9.28125C17.771 9.28125 18.9099 9.76078 19.7297 10.6309C20.5605 11.5126 20.9647 12.6973 20.8693 13.9709C20.6785 16.5 18.7191 18.5625 16.5 18.5625C14.2809 18.5625 12.3176 16.5 12.1307 13.9702C12.036 12.6863 12.4395 11.4978 13.2632 10.6232ZM16.5 27.8438C14.9857 27.8448 13.4865 27.5417 12.0915 26.9524C10.6965 26.3632 9.43401 25.4998 8.37891 24.4136C8.98319 23.5518 9.75315 22.8191 10.6438 22.2582C12.2867 21.2051 14.366 20.625 16.5 20.625C18.634 20.625 20.7133 21.2051 22.3543 22.2582C23.2457 22.8188 24.0163 23.5516 24.6211 24.4136C23.5661 25.5 22.3036 26.3634 20.9086 26.9526C19.5135 27.5419 18.0144 27.8449 16.5 27.8438Z" fill="white"/>
        </svg>

        <Typography
            sx={{
                textTransform : 'capitalize',
                fontSize : '16px'
            }}
        >
            {JSON.parse(localStorage.getItem('arhebo-admin-profile')).name}
        </Typography>
    </Box>
  )
}

export default ProfileSection